<template>
  <div class="page-block">

    <i18n-selector />

    <span @click="logout" class="button logoutbutton w-inline-block underline-link"
      v-if="clientAuthType && clientAuthType !== 'SSO'">
      <img src="@/assets/images/logout-1.svg" loading="lazy" alt="" class="logouticon">
      <div>{{ $t('dashboardPage_logout') }}</div>
    </span>

    <zhh-zedic-header :title="$t('hepProgram_homePage_title')" />

    <div class="page-block-item bodytext general-text text-justify">
      <p v-html="$t('hepProgram_homePage_pageDescription')"></p>
    </div>

    <spinning-loader v-show="showPageSpinningLoader" />

    <div class="page-container" v-show="!showPageSpinningLoader">
      <div class="page-block-item">
        <div class="expand-title" @click="expanded.first = !expanded.first">
          <span v-html="$t('hepProgram_homePage_careAboutTitle')"></span>
          <button class="expand-button">
            <img :class="{ 'upside-down': expanded.first }" src="@/assets/images/arrow-down.png" />
          </button>
        </div>
        <div v-if="expanded.first" class="expand-content">
          <span v-html="$t('hepProgram_homePage_careAboutDescription')"></span>
        </div>
      </div>

      <hr class="expand-separator"/>

      <div class="page-block-item">
        <div class="expand-title" @click="expanded.second = !expanded.second">
          <span v-html="$t('hepProgram_homePage_findVaxTitle')"></span>
          <button class="expand-button">
            <img :class="{ 'upside-down': expanded.second }" src="@/assets/images/arrow-down.png" />
          </button>
        </div>
        <div v-if="expanded.second" class="expand-content">
          <span v-html="$t('hepProgram_homePage_findVaxDescription')"></span>
        </div>
      </div>

      <hr class="expand-separator"/>

      <div class="page-block-item">
        <div class="expand-title" @click="expanded.third = !expanded.third">
          <span v-html="$t('hepProgram_homePage_unsureVaxTitle')"></span>
          <button class="expand-button">
            <img :class="{ 'upside-down': expanded.third }" src="@/assets/images/arrow-down.png" />
          </button>
        </div>
        <div v-if="expanded.third" class="expand-content">
          <span v-html="$t('hepProgram_homePage_unsureVaxDescription')"></span>
        </div>
      </div>

      <hr class="expand-separator"/>

      <div class="page-block-item">
        <div class="expand-title" @click="expanded.fourth = !expanded.fourth">
          <span v-html="$t('hepProgram_homePage_mandatoryTitle')"></span>
          <button class="expand-button">
            <img :class="{ 'upside-down': expanded.fourth }" src="@/assets/images/arrow-down.png" />
          </button>
        </div>
        <div v-if="expanded.fourth" class="expand-content">
          <span v-html="$t('hepProgram_homePage_mandatoryDescription')"></span>
        </div>
      </div>

      <hr class="expand-separator"/>

      <div class="page-block-item last-expand">
        <div class="expand-title" @click="expanded.fifth = !expanded.fifth">
          <span v-html="$t('hepProgram_homePage_testedPositiveTitle')"></span>
          <button class="expand-button">
            <img :class="{ 'upside-down': expanded.fifth }" src="@/assets/images/arrow-down.png" />
          </button>
        </div>
        <div v-if="expanded.fifth" class="expand-content">
          <span v-html="$t('hepProgram_homePage_testedPositiveDescription')"></span>
          <div>
            <span @click="goToUploadTestPage" class="button w-button menu-button mt-20">
              {{ $t('hepProgram_homePage_uploadPositiveTest') }}
            </span>
          </div>
        </div>

      </div>

      <div class="menu-container">
        <div >
          <span @click="goToVaccinationPage" class="button w-button menu-button">
            {{ $t('hepProgram_homePage_uploadVax') }}
          </span>
        </div>
        <div class="mt-20">
          <span @click="goToVoucherPage" class="button w-button menu-button">
            {{ $t('hepProgram_homePage_requestVoucher') }}
          </span>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import AuthApi from '@/api/auth.api';
import Utils from '@/utils/utils';

const CLIENT = Utils.getClient();

export default {
  name: 'HepProgram_HomePage',
  components: {
  },

  data() {
    return {
      expanded: {
        first: false,
        second: false,
        third: false,
        fourth: false,
        fifth: false,
      },
      loggedUser: undefined,
      clientAuthType: undefined,
      loadingPage: true,
      clientId: CLIENT,
    };
  },

  created() {
    if (!this.isHepProgram()) {
      this.$router.push({ name: 'AuthErrorPage' });
    } else {
      this.fetchLoggedUser();
    }
  },

  methods: {
    isHepProgram() {
      return Utils.isHepProgramClient();
    },

    async fetchLoggedUser() {
      try {
        const loggedUserResult = await AuthApi.getMe();
        this.loggedUser = loggedUserResult.data;

        const { auth } = this.loggedUser;

        this.clientAuthType = auth;
        this.loadingPage = false;
      } catch (e) {
        this.showErroAlert({
          title: 'Oops!',
          text: this.$t('somethingWentWrong'),
        });
        this.loadingPage = false;
      }
    },

    goToUploadTestPage() {
      this.$router.push({ name: 'HepProgram_UploadTestPage' });
    },

    goToVoucherPage() {
      this.$router.push({ name: 'HepProgram_VoucherPage' });
    },

    goToVaccinationPage() {
      this.$router.push({ name: 'HepProgram_VaccinationPage' });
    },

    showErroAlert(message) {
      const options = {
        ...message,
        // position: 'top-end',
      };
      return this.$swal(options);
    },

    async logout() {
      try {
        await AuthApi.logOut();
      } catch (e) {
        //
      }
    },
  },

  computed: {
    showPageSpinningLoader() {
      return this.loadingPage;
    },
  },
};
</script>
<style>
  .expand-content > span > ul  {
    padding-left: 20px !important;
  }
</style>
<style scoped>
  .page-container {
    margin-bottom: 100px;
  }

  .logoutbutton {
    width: 190px;
    color: #5031D0;
    text-decoration: none;
    position: absolute;
    right: 5%;
    margin-top: -25px;
    text-transform: uppercase;
  }

  .logouticon {
    width: 25px;
    margin-right: 12px;
  }

  .expand-title {
    text-align: left;
    display: flex;
    justify-content: space-between;
    color: #6E7E8C;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
  }

  .expand-content {
    text-align: justify;
    color: #6E7E8C;
    font-size: 16px;
    font-weight: 400;
    margin-top: 5px;
  }

  .expand-button {
    background: white;
  }

  .expand-button > img {
    width: 20px;
    height: auto;
  }

  .expand-separator {
    color: #6E7E8C;
    margin: 10px 0;
  }

  .last-expand {
    margin-bottom: 20px;
  }

  .upside-down {
    -webkit-transform:rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .menu-button {
    background: white;
    color: #5031D0;
  }

  .menu-button:hover {
    background-color: #5031D0;
    color: white;
  }

  .mt-20 {
    margin-top: 20px;
  }

  @media screen and (max-width: 479px) {
    .page-container {
      width: 100%;
    }

    .logoutbutton {
      position: relative;
      right: 0px;
      margin-top: -10px;
      margin-bottom: 20px;
      width: 100%;
    }
  }
</style>
